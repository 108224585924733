const Footer = () => {
  return (
    <footer className="flex flex-col  bg-white px-11 py-6 border-t border-gray-300 mt-28">
      <div className="flex text-center text-gray-500">
        <div className="font-light">
          &copy; {new Date().getFullYear().toString()} by Soyeon Lee
        </div>
      </div>
    </footer>
  );
};

export default Footer;
