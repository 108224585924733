export const NavData = [
  {
    title: "Skill",
    subMenu: ["Mian", "System/Tool", "Once I used"]
  },
  {
    title: "Experience",
    subMenu: ["프로그라운드", "포크쇼핑", "룸프렌즈"]
  },
  {
    title: "Project",
    subMenu: [
      "Chat Platform",
      "Local Service Provider Matching Platform",
      "석사논문"
    ]
  },
  {
    title: "Education",
    subMenu: ["석사"]
  },
  {
    title: "Certificate",
    subMenu: ["정보처리기사"]
  },
  {
    title: "Award",
    subMenu: ["교내 코딩 대회 ", "영남대 심화창의 융합양성 캠프 "]
  }
];
